@import "~antd/dist/antd.css";

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* navbar styles */
#navbar-style {
  background: white;
  box-shadow: 0 0 13px 1px dimgrey;
}

/*sidebar css*/

#sidebar-color {
  background: #001529;
}
.side-heading {
  color: white;
}
/*tab view*/
.tab-tile {
  padding: 0;
}

/*homepage tabs container*/
.tab-container {
  width: 85%;
  margin-left: 10%;
}

.tick-icon {
  font-size: 15px;
  margin: 0 15px;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 3px;
  border-radius: 20px;
  color: green;
}
.cross-icon {
  font-size: 15px;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 3px;
  border-radius: 20px;
  color: red;
}
.validate-link {
  color: blue;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}

/*form page*/
.form-submit-btn {
  width: 20%;
  margin: 6% 0% 10% 37%;
  display: inline-flex;
}

.ant-select-selection--single {
  height: 41px;
}

.ant-select-selection__rendered {
  margin: 0;
  height: 39px;
  width: 100%;
}
.ant-select-selection-selected-value {
  margin-left: 5%;
  margin-top: 2%;
}

.css-vj8t7z {
  height: 41px;
}
.css-2o5izw {
  height: 41px;
}

.mandatory-star {
  margin-left: 2%;
  height: 0;
  position: absolute;
  right: 6%;
  top: -7%;
  color: red;
  font-weight: bold;
}

.ant-select-selection__placeholder {
  margin-left: 8px;
  font-size: 16px;
}

.link {
  cursor: pointer;
  text-decoration: underline;
  color: #1c8efa;
}
.detail-value-p {
  padding-right: 25%;
}
.detail-heading {
  margin-bottom: 5px;
  color: #1c8efa;
}
.details-container {
  height: 450px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.order-details-container {
  margin: 3% 0 0 10%;
}
.details-action-view {
  margin: 8% 0 7% 0;
}
.next-previous-view {
  float: right;
  width: 17%;
  margin-top: 3%;
}
.action-buttons {
  margin-left: 10%;
}
.whatsapp-list-view {
  height: 470px;
  overflow-y: scroll;
  padding: 1%;
  overflow-x: hidden;
}

.row {
  display: flex;
}
.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.66%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.66%;
}
.col-12 {
  width: 100%;
}
.center {
  text-align: center;
}

.envelope-text {
  font-size: 16px;
  font-weight: bold;
}

.power-text {
  font-size: 20px;
  font-weight: bold;
}
